import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Layout from '../components/layouts/Layout';
import { chainLink, leftArrow, rightArrow } from '../assets/svgs';

import { products } from '../utils/dummyData';

const ViewProjects = () => {
  const params = useParams();
  const pageTitle = params?.id;

  const product = products?.find((item) => item?.name === pageTitle);
  const lastItem = products[products?.length - 1];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div className="!px-4 lg:!px-16">
        <div className="py-20">
          <h1 className=" uppercase font-bold text-4xl lg:text-8xl [text-wrap:balance] bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 to-50% to-slate-200">
            Think
            <span className=" inline-flex flex-col h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] lg:h-[calc(theme(fontSize.8xl)*theme(lineHeight.tight))] overflow-hidden">
              <ul className="md:ml-4 block animate-text-slide-4 text-left leading-tight [&_li]:block">
                <li className="text-renaissance_secondary_green">Forward</li>
                <li className="text-red_600">innovation</li>
                <li className="text-renaissance_blue_green_80">solution</li>
                <li className="text-main_blue_100 ">renaissance</li>

                <li
                  className="text-renaissance_secondary_green"
                  aria-hidden="true"
                >
                  Forward
                </li>
              </ul>
            </span>
          </h1>
        </div>

        <section>
          <div className="flex items-center">
            {product?.id !== products[0]?.id && (
              <Link to={product?.prev}>
                <button className="flex items-center gap-x-2">
                  <img src={leftArrow} alt="previous product" />
                  <span className="text-sm">Prev. Project</span>
                </button>
              </Link>
            )}

            {product?.id !== lastItem?.id && (
              <Link className="ml-auto" to={product?.next}>
                <button className="flex items-center gap-x-2">
                  <span className="text-sm">Next Project</span>
                  <img src={rightArrow} alt="next product" />
                </button>
              </Link>
            )}
          </div>

          <div className="my-12 lg:my-16 flex flex-col lg:flex-row gap-x-12 gap-y-6">
            <div className="xl:col-span-2 ">
              <div className="h-80 w-72  bg-card_white border border-renaissance_gray_4 rounded-lg">
                <div className="h-4/5 flex flex-col justify-center gap-y-4 !px-5">
                  <div>
                    {product?.tag === 'Drop' ? (
                      <img
                        height="40"
                        width="52"
                        src={product?.title_icon}
                        alt={product?.name}
                      />
                    ) : (
                      <img src={product?.title_icon} alt={product?.name} />
                    )}
                  </div>

                  <div>
                    <h3 className="text-lg font-semibold text-renaissance_black">
                      {product?.title}
                    </h3>
                    <p className="mt-10  text-sm text-renaissance_black">
                      {product?.date || 'October 2023'}
                    </p>
                  </div>
                </div>
                <div className="h-1/5  flex items-center  border-t border-renaissance_gray_3 py-5 !px-6">
                  <span className="text-sm text-renaissance_black">
                    {product?.tag}
                  </span>
                </div>
              </div>
            </div>

            <div className="md:col-span-2 xl:col-span-4">
              <span className="block mb-2 font-bold text-xl">Link</span>

              <a href={product?.preview} target="_blank" rel="noreferrer">
                <div className="w-fit bg-renaissance_black flex items-center gap-x-2 py-1 !px-3 rounded-full">
                  <img src={chainLink} alt="link to project" />

                  <span className="text-white text-sm">Demo</span>
                </div>
              </a>

              <h2 className="my-3 uppercase font-bold text-[28px]">
                Project overview
              </h2>

              <p className="text-base lg:w-3/4">{product?.project_overview}</p>

              <section
                className={`grid grid-cols-1 lg:grid-cols-2 gap-4 ${
                  product?.tag === 'Drop' ? 'max-w-4xl' : 'max-w-6xl'
                }  mt-3`}
              >
                {product?.images &&
                  product?.images?.map((item) => (
                    <div
                      className={`w-full lg:h-72 relative overflow-hidden rounded-lg`}
                    >
                      <img
                        className={` ${
                          product?.tag === 'Drop'
                            ? 'object-cover'
                            : 'object-cover'
                        }  h-full w-full`}
                        src={item?.src}
                        alt={product?.title}
                      />
                    </div>
                  ))}
              </section>

              <section className="mt-6">
                <h3 className="block mb-2 font-bold text-xl">
                  Key Contributors
                </h3>

                <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-y-4 gap-x-12">
                  {product?.contributors?.map((person) => (
                    <div>
                      <span className="block text-neutral_09 text-lg">
                        {person?.name}
                      </span>
                      <span className="text-renaissance_gray_1 text-sm">
                        {person?.title}
                      </span>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ViewProjects;
